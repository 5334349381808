import React, { useContext, useEffect, useState } from 'react';
import styles from './details.module.scss';
import { useParams } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import TableExpanded from '../../../ui/tableExpanded/tableExpanded';
import { useNavigate } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import { Row, Col } from 'react-bootstrap';
import { budgetItemModel } from '../../../../models/budget';
import { AuthContext } from '../../../../providers/AuthProvider';
import { SolicitationService } from '../../../../services/solicitation.service';
import { SolicitationStatusModel } from '../../../../consts/solicitationStatus';
import { paymentTypesModel } from '../../../../consts/paymentTypes';
import { defineShippingAddressId, normalizeSolicitationStatus } from '../../../utils/utils';
import { setDriverAproved, setDriverOnTheWay, setDriverSelected, setDriverWaiting, setEstimativeAproved, setEstimativeCreated, setPassangerDisembarked, setSolicitationCanceled, setTripComplete, setTripInProgress } from '../../../utils/solicitationLifecycle';
import { DriverService } from '../../../../services/driver.service';
import { CarService } from '../../../../services/car.service';
import ErrorModal from '../../../ui/ErrorModal/ErrorModal';
import SuccessModal from '../../../ui/SuccessModal/SuccessModal';
import { DriversScheduleColumns, PassengersColumns, TripColumns, ValuesColumns, customSort } from '../../../../utils/dataTableColumns';

const SolicitationDetails = () => {
  const navigate = useNavigate();
  let uid = useParams().uid
  const { user } = useContext(AuthContext);
  const solicitationService = new SolicitationService(user);
  const driverService = new DriverService(user);
  const carService = new CarService(user);

  const [driver, setDriver] = useState(""); // eslint-disable-line no-unused-vars
  const [driverSchedule, setDriverSchedule] = useState([]);
  const [driverList, setDriverList] = useState([]);
  const [budget, setBudget] = useState([]);
  const [budgetItem, setBudgetItem] = useState(budgetItemModel);
  const [data, setData] = useState();
  const [ErrorModalMessage, setErrorModalMessage] = useState('');
  const [SuccessModalMessage, setSuccessModalMessage] = useState('');
  const [updateEstimate, setUpdateEstimate] = useState(false);
  const [updateDriver, setUpdateDriver] = useState(false);

  const formatAddresses = (addresses) => {
    if (!addresses) return;
    let newRoute = addresses.map((address, index) => {
      if (index === (addresses.length - 1)) return undefined;
      const destiny = `${addresses[index + 1].address.street}, ${addresses[index + 1].address.number}, ${addresses[index + 1].address.district}, ${addresses[index + 1].address.city}, ${addresses[index + 1].address.state} - ${addresses[index + 1].address.cep}`;
      let route = {
        id: index,
        date: address.address.datetime.toDate().toLocaleString(),
        origin: `${address.address.street}, ${address.address.number}, ${address.address.district}, ${address.address.city}, ${address.address.state} - ${address.address.cep}`
      }
      if (destiny) route.destiny = destiny
      return route;
    })

    return newRoute.filter(element => element !== undefined);
  }

  useEffect(() => {
    solicitationService.getSolicitation(uid).then((result) => {
      const solicitationData = {
        voucher: result.voucher,
        tripData: formatAddresses(result.routeAdresses),
        passengersData: result.passengers,
        paymentMethod: paymentTypesModel.find(method => method.value === result.paymentMethod).label,
        observations: result.obs,
        status: normalizeSolicitationStatus(result),
        estimate: result.estimate,
        type: result.type,
        startDate: result.startDate,
        endDate: result.endDate,
        driverData: {},
        shippingAddressId: result.shippingAddressId,
      }
      if (result.originalSolicitation) {
        solicitationData.originalSolicitation = result.originalSolicitation
      }
      if (result.driver) {
        driverService.getDriver(result.driver).then((driverData) => {
          solicitationData.driverData = driverData;
          carService.getCarByDriver(result.driver).then((carData) => {
            solicitationData.driverData.car = carData[0];
            setData(solicitationData);
          })
        })
      } else {
        setData(solicitationData);
      }
      if ((solicitationData.status === SolicitationStatusModel.estimativeAproved.label) || user.userInfo.isAdmin) {
        setDriverList(getDriversList());
      }
    })
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const getTotalItem = (bItem) => {
    if (bItem.type === 'dynamic') return bItem.total;
    return (bItem.quantity * bItem.unity) - bItem.discount;
  }

  let conditionalRowStyles = [
    {
      when: row => row.type === "dynamic",
      style: {
        fontWeight: "bold",
        border: "none"
      },

    },
  ]

  const chooseDriver = (id) => {
    setDriver(id)
    setDriverSchedule(getDriverSchedule(id));
  }

  const checkDisabled = () => {
    if (budgetItem.description && budgetItem.quantity && (budgetItem.unity || budgetItem.discount)) {
      return false;
    }
    return true;
  }

  const createNewBudgetItem = () => {
    setBudget(current => [...current, budgetItem]);
    setBudgetItem(budgetItemModel);
  }

  const calcValues = (budgetData) => {
    if (budgetData.length === 0) return []
    let values = budgetData.map((item) => {
      item = {
        ...item,
        total: ((item.quantity * item.unity) - item.discount)
      }
      return item;
    });
    let initialValue = 0;
    let total = values.reduce((accumulator, currentValue) => accumulator + currentValue.total, initialValue)
    values.push({
      description: "Total",
      type: "dynamic",
      total: total
    })
    return values
  }

  const updateStatus = (setStatusFunc) => {
    solicitationService.getSolicitation(uid).then((result) => {
      try {
        solicitationService.updateSolicitation({ status: setStatusFunc(result).value }, uid).then(() => {
          setSuccessModalMessage('Solicitação atualizada com sucesso!');
        })
      } catch (e) {
        setErrorModalMessage(e.message)
      }
    })
  }

  const nextAddressIsAStop = () => {
    const shippingAddressId = data.shippingAddressId;
    return Boolean(data.tripData[shippingAddressId + 1]);
  }

  const submitDriverWaiting = () => {
    solicitationService.getSolicitation(uid).then((result) => {
      try {
        const shippingAddressId = defineShippingAddressId(result)
        solicitationService.updateSolicitation({ status: setDriverWaiting(result).value, shippingAddressId: shippingAddressId }, uid).then(() => {
          setSuccessModalMessage('Solicitação atualizada com sucesso!');
        })
      } catch (e) {
        setErrorModalMessage(e.message)
      }
    })
  }

  const submitBudget = () => {
    solicitationService.updateSolicitation({ estimate: budget }, uid).then(() => {
      solicitationService.getSolicitation(uid).then((result) => {
        if (result.status !== SolicitationStatusModel.created.value) {
          return setSuccessModalMessage('Solicitação atualizada com sucesso!');
        }
        solicitationService.updateSolicitation({ status: setEstimativeCreated(result).value }, uid).then(() => {
          setSuccessModalMessage('Solicitação atualizada com sucesso!');
        }).catch((error) => {
          setErrorModalMessage('Não foi possivel atualizar a solicitação.')
        })
      }).catch((error) => {
        setErrorModalMessage('')
      })
    }).catch((error) => {
      setErrorModalMessage('Não foi possivel atualizar a solicitação.')
    })
  }

  const cancelSolicitation = () => {
    solicitationService.getSolicitation(uid).then((result) => {
      solicitationService.updateSolicitation({ status: setSolicitationCanceled(result).value }, uid).then(() => {
        setSuccessModalMessage('Solicitação cancelada com sucesso!');
      }).catch((error) => {
        setErrorModalMessage('Não foi possivel cancelar a solicitação.')
      })
    }).catch((error) => {
      setErrorModalMessage('')
    })
  }

  const submitDriver = () => {
    solicitationService.updateSolicitation({ driver: driver }, uid).then(() => {
      solicitationService.getSolicitation(uid).then((result) => {
        if (result.status !== SolicitationStatusModel.estimativeAproved.value) {
          return setSuccessModalMessage('Solicitação atualizada com sucesso!');
        }
        solicitationService.updateSolicitation({ status: setDriverSelected(result).value }, uid).then(() => {
          setSuccessModalMessage('Solicitação atualizada com sucesso!');
        }).catch((error) => {
          setErrorModalMessage('Não foi possivel atualizar a solicitação.')
        })
      }).catch((error) => {
        setErrorModalMessage('');
      })
    }).catch((error) => {
      setErrorModalMessage('Não foi possivel atualizar a solicitação.')
    })
  }

  const getDriversList = () => {
    if (!user.userInfo.isAdmin) return [];
    driverService.getAllDrivers().then((result) => {
      setDriverList(result);
    })
  }

  const getDriverSchedule = (driverId) => {
    solicitationService.getDriversSolicitation(driverId, data.startDate, data.endDate).then((result) => {
      setDriverSchedule(result);
    })
  }

  const onModalClose = () => {
    setSuccessModalMessage('');
    setErrorModalMessage('');
    navigate('/')
  }

  const changeEstimate = () => {
    setUpdateDriver(false);
    setUpdateEstimate(true);
    setBudget(data.estimate);
  }

  const deleteEstimate = (id) => {
    let newBudget = [...budget]
    if (id > -1) {
      newBudget.splice(id, 1);
      setBudget(newBudget);
    }
  }

  const changeDriver = () => {
    setUpdateEstimate(false);
    setUpdateDriver(true);
    setDriver(data.driverData.uid);
  }

  const cancelEdit = () => {
    setUpdateDriver(false);
    setUpdateEstimate(false);
    setDriver('');
    setBudget([]);
  }

  const isCancelButtonEnabled = () => {
    if (user.userInfo.isAdmin && ![SolicitationStatusModel.tripCompleted.label, SolicitationStatusModel.solicitationCanceled.label].includes(data.status)) return true;
    return [
      SolicitationStatusModel.created.label,
      SolicitationStatusModel.estimativeCreated.label,
      SolicitationStatusModel.driverAproved.label,
      SolicitationStatusModel.driverSelected.label,
      SolicitationStatusModel.estimativeAproved.label
    ].includes(data.status) && (!user.userInfo.isDriver)
  }

  if (!data) return <div></div>
  return (
    <div className={styles.Details}>
      <div className={styles.Header}>
        <h1>Dados da solicitação</h1>
        <div className={styles.HeaderItems}>
          <div>
            <h3>Serviço: {data.voucher}</h3>
            {data.originalSolicitation &&
              <h5><a href={'/solicitation/detail/' + data.originalSolicitation}>Solicitação original</a></h5>}
          </div>
          <div className={styles.StatusContainer}>
            <h4 className={styles.StatusTitle}>Status: {data.status}</h4>
            {data.originalSolicitation &&
              <small className='text-muted'>Solicitação atualizada</small>}
          </div>
        </div>
      </div>
      <div className={styles.sectionContainer}>
        <h6>
          Sobre a viagem
        </h6>
        <DataTable
          columns={TripColumns()}
          expandableRows
          sortFunction={customSort}
          expandableRowsComponent={TableExpanded}
          data={data.tripData}
        ></DataTable>
      </div>
      {
        data.shippingAddressId != null &&
        <div className={styles.sectionContainer}>
          <h6>
            Endereço de embarque
          </h6>
          <p><p className={styles.textTitle}>Endereço: </p>{data.tripData[data.shippingAddressId].origin}</p>
        </div>
      }
      <div className={styles.sectionContainer}>
        <h6>
          Sobre os passageiros
        </h6>
        <DataTable
          columns={PassengersColumns()}
          data={data.passengersData}
          expandableRows
          sortFunction={customSort}
          expandableRowsComponent={TableExpanded}
        ></DataTable>
      </div>
      <div className={styles.sectionContainer}>
        <h6>
          Informações adicionais
        </h6>
        <p><p className={styles.textTitle}>Forma de pagamento: </p>{data.paymentMethod}</p>
        <p><p className={styles.textTitle}>Observações: </p>{data.observations}</p>
      </div>
      {
        data.driverData.uid && !updateDriver &&
        <div className={styles.sectionContainer}>
          <h6>Sobre o motorista</h6>
          <span><p className={styles.textTitle}>Nome:</p> {data.driverData.name}</span>
          {data.driverData.car &&
            <span><p className={styles.textTitle}>Carro:</p> {`${data.driverData.car.brand} ${data.driverData.car.model} ${data.driverData.car.color}, ${data.driverData.car.license}`}</span>
          }
          {
            user.userInfo.isAdmin &&
            <button className={styles.SecondaryButton} onClick={() => changeDriver()}>Alterar motorista</button>
          }
        </div>
      }
      {
        ((driverList && user.userInfo.isAdmin && data.status === SolicitationStatusModel.estimativeAproved.label) || updateDriver) &&
        <div className={styles.sectionContainer}>
          <h6>Selecionar Motorista</h6>
          <Form.Select aria-label="Selecione um motorista" onChange={(el) => chooseDriver(el.target.value)} value={driver}>
            <option>Selecione um motorista</option>
            {driverList.map(driver => <option value={driver.id} selected={driver.id === driver}>{driver.name}</option>)}
          </Form.Select>
          {driverSchedule && driverSchedule.length > 0 && (
            <div >
              <h5>Agendamentos do motorista para esta data:</h5>
              <DataTable
                columns={DriversScheduleColumns()}
                sortFunction={customSort}
                data={driverSchedule}
              ></DataTable>
            </div>
          )}
        </div>
      }
      {
        ((data.estimate.length > 0 && !user.userInfo.isDriver) && !updateEstimate) &&
        <div className={styles.sectionContainer}>
          <h6>Orçamento</h6>
          <DataTable
            columns={ValuesColumns()}
            sortFunction={customSort}
            data={calcValues(data.estimate)}
            conditionalRowStyles={conditionalRowStyles}
          ></DataTable>
          {
            user.userInfo.isAdmin &&
            <button className={styles.SecondaryButton} onClick={() => changeEstimate()}>Editar orçamento</button>
          }
        </div>
      }
      {(data.status === SolicitationStatusModel.created.label || updateEstimate) && user.userInfo.isAdmin &&
        <div className={styles.sectionContainer}>
          <h6>Insira o orçamento</h6>
          <DataTable
            columns={ValuesColumns(deleteEstimate)}
            sortFunction={customSort}
            data={calcValues(budget)}
            conditionalRowStyles={conditionalRowStyles}
          ></DataTable>
          <Row>
            <Form.Group as={Col} md="2" controlId="descriptionInput">
              <Form.Label>Descrição do gasto</Form.Label>
              <Form.Control
                name='description'
                type="text"
                placeholder="Descrição do gasto"
                value={budgetItem.description}
                onChange={($event) => setBudgetItem({ ...budgetItem, description: $event.target.value })}
              />
            </Form.Group>
            <Form.Group as={Col} md="2" controlId="quantityInput">
              <Form.Label>Quantidade</Form.Label>
              <Form.Control
                name='quantity'
                type="number"
                placeholder="Quantidade"
                value={budgetItem.quantity}
                onChange={($event) => setBudgetItem({ ...budgetItem, quantity: parseFloat($event.target.value) })}
              />
            </Form.Group>
            <Form.Group as={Col} md="2" controlId="unityInput">
              <Form.Label>Valor</Form.Label>
              <Form.Control
                name='unity'
                type="number"
                placeholder="Valor"
                value={budgetItem.unity}
                onChange={($event) => setBudgetItem({ ...budgetItem, unity: parseFloat($event.target.value) })}
              />
            </Form.Group>
            <Form.Group as={Col} md="2" controlId="discountInput">
              <Form.Label>Desconto</Form.Label>
              <Form.Control
                name='discount'
                type="number"
                placeholder="Desconto"
                value={budgetItem.discount}
                onChange={($event) => setBudgetItem({ ...budgetItem, discount: parseFloat($event.target.value) })}
              />
            </Form.Group>
            <Form.Group as={Col} md="2" controlId="totalInput">
              <Form.Label>Total</Form.Label>
              <Form.Control
                name='total'
                disabled={true}
                type="text"
                placeholder="Total"
                value={getTotalItem(budgetItem)}
              />
            </Form.Group>
            <Col>
              <button className={styles.PrimaryButton} disabled={checkDisabled()} onClick={() => createNewBudgetItem()}>Adicionar</button>
            </Col>
          </Row>
          <div>

          </div>
        </div>
      }
      <div className={styles.InputContainer}>
        <div className={styles.buttons}>
          {
            !updateDriver && !updateEstimate && ((![SolicitationStatusModel.solicitationCanceled.label, SolicitationStatusModel.tripCompleted].includes(data.status) && (!user.userInfo.isDriver && user.userInfo.isReliable)) || user.userInfo.isAdmin) &&
            <button className={styles.SecondaryButton} onClick={() => navigate('/solicitation/update/' + uid)}>Editar solicitação</button>
          }
          {
            !updateDriver && !updateEstimate && ([SolicitationStatusModel.created.label, SolicitationStatusModel.estimativeCreated.label, SolicitationStatusModel.estimativeAproved.label].includes(data.status) && (!user.userInfo.isDriver && !user.userInfo.isAdmin && !user.userInfo.isReliable)) &&
            <button className={styles.SecondaryButton} onClick={() => navigate('/solicitation/update/' + uid)}>Editar solicitação</button>
          }
          {
            isCancelButtonEnabled() &&
            <button className={styles.DangerButton} onClick={() => cancelSolicitation()}>Cancelar solicitação</button>
          }
          {
            (updateDriver || updateEstimate) &&
            <button className={styles.DangerButton} onClick={() => cancelEdit()}>Cancelar edição</button>
          }
          {
            ((data.estimate !== budget && budget.length > 0) || updateEstimate) &&
            <button className={styles.PrimaryButton} onClick={() => submitBudget()}>Salvar orçamento</button>
          }
          {
            driver && user.userInfo.isAdmin &&
            <button className={styles.PrimaryButton} onClick={() => submitDriver()}>Salvar motorista</button>
          }
          {
            data.status === SolicitationStatusModel.estimativeCreated.label && (!user.userInfo.isAdmin && !user.userInfo.isDriver) &&
            <button className={styles.PrimaryButton} onClick={() => updateStatus(setEstimativeAproved)}>Aprovar orçamento</button>
          }
          {
            data.status === SolicitationStatusModel.driverSelected.label && (!user.userInfo.isAdmin && user.userInfo.isDriver) &&
            <button className={styles.PrimaryButton} onClick={() => updateStatus(setDriverAproved)}>Aprovar viagem</button>
          }
          {
            data.status === SolicitationStatusModel.driverAproved.label && (!user.userInfo.isAdmin && user.userInfo.isDriver) &&
            <button className={styles.PrimaryButton} onClick={() => updateStatus(setDriverOnTheWay)}>Motorista está a caminho</button>
          }
          {
            [SolicitationStatusModel.driverOnTheWay.label, SolicitationStatusModel.passangerDisembarked.label].includes(data.status) && (!user.userInfo.isAdmin && user.userInfo.isDriver) &&
            <button className={styles.PrimaryButton} onClick={() => submitDriverWaiting()}>Motorista está aguardando</button>
          }
          {
            data.status === SolicitationStatusModel.driverWaiting.label && (!user.userInfo.isAdmin && user.userInfo.isDriver) &&
            <button className={styles.PrimaryButton} onClick={() => updateStatus(setTripInProgress)}>Viagem em andamento</button>
          }
          {
            data.status === SolicitationStatusModel.tripInProgress.label && (!user.userInfo.isAdmin && user.userInfo.isDriver) && nextAddressIsAStop() &&
            <button className={styles.PrimaryButton} onClick={() => updateStatus(setPassangerDisembarked)}>Passageiro desembarcou</button>
          }
          {
            data.status === SolicitationStatusModel.tripInProgress.label && (!user.userInfo.isAdmin && user.userInfo.isDriver) && !nextAddressIsAStop() &&
            <button className={styles.PrimaryButton} onClick={() => updateStatus(setTripComplete)}>Viagem concluída</button>
          }
          <button className={styles.PrimaryButton} onClick={() => window.history.back()}>Voltar</button>
        </div>
      </div>
      <ErrorModal show={Boolean(ErrorModalMessage)} message={ErrorModalMessage} onClose={onModalClose}></ErrorModal>
      <SuccessModal show={Boolean(SuccessModalMessage)} message={SuccessModalMessage} onClose={onModalClose}></SuccessModal>
    </div>
  );
}

SolicitationDetails.propTypes = {};

SolicitationDetails.defaultProps = {};

export default SolicitationDetails;
