import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styles from './guestDetails.module.scss';


import { useParams } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import TableExpanded from '../../../ui/tableExpanded/tableExpanded';
import { AuthContext } from '../../../../providers/AuthProvider';
import ErrorModal from '../../../ui/ErrorModal/ErrorModal';
import { PassengersColumns, TripColumns, customSort } from '../../../../utils/dataTableColumns';
import GuestInfoModal from '../../../ui/guestInfoModal/guestInfoModal';
import { FunctionsService } from '../../../../services/functions.service';
import { normalizeSolicitationStatus } from '../../../utils/utils';


const GuestDetails = () => {
  let uid = useParams().solicitation;
  let passengerUid = useParams().passenger;

  const functionsService = new FunctionsService();

  const [data, setData] = useState();
  const [userData, setUserData] = useState({ solicitation: uid, passenger: passengerUid, birth: '', phone: '' });
  const [ErrorModalMessage, setErrorModalMessage] = useState('');

  const formatAddresses = (addresses) => {
    if (!addresses) return;
    let newRoute = addresses.map((address, index) => {
      if (index === (addresses.length - 1)) return undefined;
      const destiny = `${addresses[index + 1].address.street}, ${addresses[index + 1].address.number}, ${addresses[index + 1].address.district}, ${addresses[index + 1].address.city}, ${addresses[index + 1].address.state} - ${addresses[index + 1].address.cep}`;
      let route = {
        id: index,
        date: new Date(address.address.datetime._seconds * 1000).toLocaleString(),
        origin: `${address.address.street}, ${address.address.number}, ${address.address.district}, ${address.address.city}, ${address.address.state} - ${address.address.cep}`
      }
      if (destiny) route.destiny = destiny
      return route;
    })
    return newRoute.filter(element => element !== undefined);
  }

  useEffect(() => {
    if (!userData.birth || !userData.phone) return;
    functionsService.getGuestSolicitation(userData).then(result => {
      const solicitationData = {
        voucher: result.voucher,
        tripData: formatAddresses(result.routeAdresses),
        passengersData: result.passengers,
        observations: result.obs,
        status: normalizeSolicitationStatus(result),
        type: result.type,
        startDate: result.startDate,
        endDate: result.endDate,
        driverData: result.driverData,
        shippingAddressId: result.shippingAddressId,
      }
      setData(solicitationData);
    }).catch(err => {
      setErrorModalMessage(err.message || 'Não foi possível completar a solicitação.')
    })
  }, [userData])

  const onDataSend = (data) => {
    setUserData(data)
  }

  const onModalClose = () => {
    setErrorModalMessage('')
  }

  if (!data) return <GuestInfoModal show={Boolean(!data) && Boolean(!ErrorModalMessage)} onSend={onDataSend} data={userData} />
  return (
    <div className={styles.Details}>
      <div className={styles.Header}>
        <div>
          <h3>Serviço: {data.voucher}</h3>
        </div>
        <div className={styles.StatusContainer}>
          <h4 className={styles.StatusTitle}>Status: {data.status}</h4>
          {data.originalSolicitation &&
            <small className='text-muted'>Solicitação atualizada</small>}
        </div>
      </div>
      <div className={styles.sectionContainer}>
        <h6>
          Sobre a viagem
        </h6>
        <DataTable
          columns={TripColumns()}
          expandableRows
          sortFunction={customSort}
          expandableRowsComponent={TableExpanded}
          data={data.tripData}
        ></DataTable>
      </div>
      {
        data.shippingAddressId != null &&
        <div className={styles.sectionContainer}>
          <h6>
            Endereço de embarque
          </h6>
          <p><p className={styles.textTitle}>Endereço: </p>{data.tripData[data.shippingAddressId].origin}</p>
        </div>
      }
      <div className={styles.sectionContainer}>
        <h6>
          Sobre os passageiros
        </h6>
        <DataTable
          columns={PassengersColumns()}
          data={data.passengersData}
          expandableRows
          sortFunction={customSort}
          expandableRowsComponent={TableExpanded}
        ></DataTable>
      </div>
      <div className={styles.sectionContainer}>
        <h6>
          Informações adicionais
        </h6>
        <p><p className={styles.textTitle}>Observações: </p>{data.observations}</p>
      </div>
      {
        data.driverData &&
        <div className={styles.sectionContainer}>
          <h6>Sobre o motorista</h6>
          <span><p className={styles.textTitle}>Nome:</p> {data.driverData.name}</span>
          {data.driverData.car &&
            <span><p className={styles.textTitle}>Carro:</p> {`${data.driverData.car.brand} ${data.driverData.car.model} ${data.driverData.car.color}, ${data.driverData.car.license}`}</span>
          }
        </div>
      }
      <ErrorModal show={Boolean(ErrorModalMessage)} message={ErrorModalMessage} onClose={onModalClose}></ErrorModal>
    </div>
  );
};

GuestDetails.propTypes = {};

GuestDetails.defaultProps = {};

export default GuestDetails;
