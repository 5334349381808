import React from 'react';
import PropTypes from 'prop-types';
import styles from './Passengers.module.scss';
import { AuthContext } from '../../../providers/AuthProvider';
import { AddressService } from '../../../services/address.service';
import { AddressColumns, HomePassengersColumns, PassengersColumns, customSort } from '../../../utils/dataTableColumns';
import DataTable from 'react-data-table-component';
import TableExpanded from '../../ui/tableExpanded/tableExpanded';
import { PassengerService } from '../../../services/passengers.service';

class Passengers extends React.Component {
  static contextType = AuthContext;
  
  data = []

  constructor(props) {
    super(props);
    this.state = {
      showModal: false
    }
  }

  async componentDidMount() {
    this.user = this.context.user
    this.passengerService = new PassengerService(this.user);
    this.passengerService.getUserPassengers().then((result)=> {
      this.data = result;
      this.forceUpdate();
    })

  }

  onRowClick = (uid) => {
    return this.props.navigate("/passenger/" + uid)
  }

  render() {
    if(!this.user) return <div></div>;
    return (
      <div className={styles.Passengers}>
        <div className={styles.Header}>
          <h1>Passageiros</h1>
          <div className={styles.HeaderItems}>
            <div className={styles.StatusContainer}>
            </div>
            <div className={styles.buttonContainer }>
              <button className={styles.SecondaryButton} onClick={() => this.props.navigate("/passengers/create")}>Adicionar Passageiro</button>
            </div>
          </div>
        </div>
        <div className={styles.ListData}>
          <div>
            <DataTable
              pagination
              columns={HomePassengersColumns(this.onRowClick, this.user.userInfo.isAdmin)}
              data={this.data}
              sortFunction={customSort}
              noDataComponent="Não existem registros para serem exibidos."
              ></DataTable>
          </div>
        </div>
      </div>
    )
  }
}
  
Passengers.propTypes = {};

Passengers.defaultProps = {};

export default Passengers;
