import React, { useContext, useEffect, useState } from 'react';
import styles from './UserDetails.module.scss';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { AuthContext } from '../../../providers/AuthProvider';
import { UserService } from '../../../services/user.service';
import { useNavigate, useParams } from 'react-router-dom';

import UserGeneric from '../../../assets/images/profile.png'
import { CarService } from '../../../services/car.service';

const UserDetails = () => {
  const navigate = useNavigate();
  const { user } = useContext(AuthContext)
  const uid = useParams().uid || user.uid;
  const [data, setData] = useState();
  const userService = new UserService(user);
  const carService = new CarService(user);


  useEffect(() => {
    userService.getUser(uid).then((result) => {
      if(result.isDriver) {
        carService.getCarByDriver(uid).then((carResponse) => {
          result = {...result, carInfo: carResponse[0]};
          return setData(result);
        })
      }
      return setData(result);
    })
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const updateUser = () => {
    if(user.userInfo.isAdmin) {
      return navigate('/profile/update/' + uid);
    }
    return navigate('/profile/update');
  };

  if(!data) return <div></div>
  return (
    <div className={styles.UserDetails}>
        <div className={styles.Header}>
          <h1>Detalhes do usuário</h1>
        </div>
        <div className={styles.sectionContainer}>
          <h6 className='mb-3'>Informações de login</h6>
            <Row className="justify-content-md-center mb-3 mt-3">
              <Col xs={6} md={1}>
                <img src={data.photoUrl || UserGeneric} className={styles.personal_avatar} alt="avatar" />
              </Col>
            </Row>
          
          <Row className={data.photo && 'justify-content-md-center mb-3'}>
            <Form.Group as={Col} md="4" controlId="emailInput">
              <Form.Label>Email</Form.Label>
              <p>{data.email}</p>
            </Form.Group>
            {
              data.voucherId &&
              <Form.Group as={Col} md="4" controlId="emailInput">
                <Form.Label>Voucher Id</Form.Label>
                <p>{data.voucherId}</p>
              </Form.Group>
            }
            <Form.Group as={Col} md="4" controlId="emailInput">
              <Form.Label>Status do cliente</Form.Label>
              {
                data.voucherId || data.isAdmin? 
                <p className={styles.textSuccess} >Ativo</p>:
                <p className={styles.textDanger}>Inativo</p>
              }
            </Form.Group>
          </Row>
        </div>
        <div className={styles.sectionContainer}>
          <h6>Identificação</h6>
          <Row className="mb-3">
            <Form.Group as={Col} md="5" controlId="nameInput">
              <Form.Label>Nome da empresa/usuário</Form.Label>
              <p>{data.name}</p>
            </Form.Group>
            <Form.Group as={Col} md="3" controlId="cpfInput">
              <Form.Label>CPF/CNPJ</Form.Label>
              <p>{data.cpf_cnpj}</p>
            </Form.Group>
            {
              data.rg &&
              <Form.Group as={Col} md="4" controlId="cnhInput">
                <Form.Label>RG</Form.Label>
                <p>{data.rg}</p>
              </Form.Group>
            }
          </Row>
        </div>
        <div className={styles.sectionContainer}>
          <h6>Identificação</h6>
          <Row className="mb-3">
            <Form.Group as={Col} md="6" controlId="contactEmailInput">
              <Form.Label>Email para contato</Form.Label>
              <p>{data.contact_email}</p>
            </Form.Group>
            <Form.Group as={Col} md="3" controlId="phoneInput">
              <Form.Label>Telefone</Form.Label>
              <p>{data.contact_phone}</p>
            </Form.Group>
            <Form.Group as={Col} md="3" controlId="celphoneInput">
              <Form.Label>Celular</Form.Label>
              <p>{data.contact_celphone}</p>
            </Form.Group>
          </Row>
        </div>
        <div className={styles.sectionContainer}>
          <h6>Endereço</h6>
          <Row className="mb-3">
            <Form.Group as={Col} md="5" controlId="StreetInput">
              <Form.Label>Rua</Form.Label>
              <p>{data.address.street}</p>
            </Form.Group>
            <Form.Group as={Col} md="2" controlId="NumberInput">
              <Form.Label>Número</Form.Label>
              <p>{data.address.number}</p>
            </Form.Group>
            <Form.Group as={Col} md="3" controlId="DistrictInput">
              <Form.Label>Bairro</Form.Label>
              <p>{data.address.district}</p>
            </Form.Group>
            <Form.Group as={Col} md="2" controlId="StateInput">
              <Form.Label>Estado</Form.Label>
              <p>{data.address.state}</p>
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group as={Col} md="5" controlId="CityInput">
              <Form.Label>Cidade</Form.Label>
              <p>{data.address.city}</p>
            </Form.Group>
            <Form.Group as={Col} md="3" controlId="CEPInput">
              <Form.Label>CEP</Form.Label>
              <p>{data.address.cep}</p>
            </Form.Group>
            <Form.Group as={Col} md="4" controlId="ReferenceInput">
              <Form.Label>Ponto de referência</Form.Label>
              <p>{data.address.complement}</p>
            </Form.Group>
          </Row>
        </div>
        {data.isAdmin && <div className={styles.sectionContainer}>
          <h6>Informações administrativas</h6>
          <Row className="mb-3">
            <Form.Group as={Col} md="5" controlId="isAdmin">
              <Form.Check type="switch" checked={data.isAdmin} disabled label="É Administrador" />
            </Form.Group>
            <Form.Group as={Col} md="5" controlId="isDriver">
              <Form.Check type="switch" checked={data.isDriver} disabled label="É Motorista" />
            </Form.Group>
          </Row>
          {
            data.isDriver &&
            <Row className="mb-3">
              <Form.Group as={Col} md="2" controlId="cnh">
                <Form.Label>CNH</Form.Label>
                <p>{data.cnh}</p>
              </Form.Group>
              <Form.Group as={Col} md="3" controlId="cnhValid">
                <Form.Label>CNH Válida até</Form.Label>
                <p>{data.cnhValid}</p>
              </Form.Group>
            </Row>
          }
        </div>}
        {
        data.isDriver && <div className={styles.sectionContainer}>
          <h6>Informações do motorista</h6>
            <Row className="mb-3">
              <Form.Group as={Col} md="2" controlId="cnh">
                <Form.Label>CNH</Form.Label>
                <p>{data.cnh}</p>
              </Form.Group>
              <Form.Group as={Col} md="3" controlId="cnhValid">
                <Form.Label>CNH Válida até</Form.Label>
                <p>{data.cnhValid}</p>
              </Form.Group>
            </Row>
        </div>
        }
        {((data.isDriver || data.isAdmin) && data.carInfo?.uid) &&
          <div className={styles.sectionContainer}>
            <h6>Carro atual do motorista</h6>
              <Row className="mb-3">
              <Form.Group as={Col} md="4" controlId="brandInput">
                <Form.Label>Marca</Form.Label>
                <p>{data.carInfo.brand}</p>
              </Form.Group>
              <Form.Group as={Col} md="4" controlId="modelInput">
                <Form.Label>Modelo</Form.Label>
                <p>{data.carInfo.model}</p>
              </Form.Group>
              <Form.Group as={Col} md="4" controlId="licenseInput">
                <Form.Label>Placa</Form.Label>
                <p>{data.carInfo.license}</p>
              </Form.Group>
              <Form.Group as={Col} md="4" controlId="colorInput">
                <Form.Label>Cor</Form.Label>
                <p>{data.carInfo.color}</p>
              </Form.Group>
              </Row>
          </div>
        }
        <div className={styles.InputContainer}>
          <div className={styles.Buttons}>
            <button className={styles.PrimaryButton} onClick={() => updateUser()}>Editar</button>
            <button className={styles.SecondaryButton} onClick={() => window.history.back()}>Voltar</button>
          </div>
        </div>
      </div>
  )
};

UserDetails.propTypes = {};

UserDetails.defaultProps = {};

export default UserDetails;
