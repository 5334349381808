import React from 'react';
import PropTypes from 'prop-types';
import styles from './UpdateSolicitationModal.module.scss';
import { Modal } from 'react-bootstrap';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const UpdateSolicitationModal = (props) => {
  return (
    <Modal show={props.show} onHide={() => props.onConfirm(false)} aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Body className={styles.SolicitationModal}>
        <div className='text-center m-3'>
          <div>
            <FontAwesomeIcon icon={ faCheckCircle } size='4x' color='green'/>
            <h4 >Deseja manter o mesmo orçamento e motorista para a solicitação?</h4>
          </div>
          <div className={styles.buttonContainer}>
            <button className={styles.PrimaryButton} onClick={() => props.onConfirm(true)}>Sim</button>
            <button className={styles.SecondaryButton} onClick={() => props.onConfirm(false)}>Não</button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}

UpdateSolicitationModal.propTypes = {
  show: PropTypes.bool,
  onConfirm: PropTypes.func
};

UpdateSolicitationModal.defaultProps = {};

export default UpdateSolicitationModal;
