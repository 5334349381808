import React from 'react';
import styles from './home.module.scss';
import DataTable from 'react-data-table-component';
import TableExpanded from '../../ui/tableExpanded/tableExpanded';
import SolicitationModal from '../../ui/SolicitationModal/SolicitationModal';
import { AuthContext } from '../../../providers/AuthProvider';
import { SolicitationService } from '../../../services/solicitation.service';
import { formatHomeData } from '../../utils/utils';
import { CarService } from '../../../services/car.service';
import CarModal from '../../ui/CarModal/CarModal';
import ErrorModal from '../../ui/ErrorModal/ErrorModal';
import SuccessModal from '../../ui/SuccessModal/SuccessModal';
import { customSort, homeColumns } from '../../../utils/dataTableColumns';
import FilterModal from '../../ui/FilterModal/FilterModal';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DriverService } from '../../../services/driver.service';

class Home extends React.Component {
  static contextType = AuthContext;

  data = []

  driversList = [];

  // NEEDADMIN = ['Criado', 'Orçamento aprovado'];
  // NEEDDRIVER = ['Motorista encontrado'];
  // NEEDDRIVERATENTION = ['Motorista confirmado', 'Motorista a caminho', 'Motorista aguardando', 'Viagem em andamento', 'Passageiro desembarcou']

  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      showCarModal: false,
      showFilterModal: false,
      SuccessModalMessage: '',
      ErrorModalMessage: ''
    }
  }

  async componentDidMount() {
    this.user = this.context.user
    this.solicitationService = new SolicitationService(this.user);
    this.solicitationService.getAllUserSolicitations().then((result) => {
      this.data = formatHomeData(result);
      if (this.user.userInfo && this.user.userInfo.isDriver) {
        this.carService = new CarService(this.user);
        this.carService.getCarByDriver(this.user.uid).then((carData) => {
          this.selectedCar = carData[0];
          return this.forceUpdate();
        })
      } else if (!this.user.userInfo.isDriver) {
        this.driverService = new DriverService(this.user);
        this.driverService.getAllDrivers().then((drivers) => {
          this.driversList = drivers;
          this.data = this.data.map(solicitation => {
            if (!solicitation.driverId) return solicitation;
            return { ...solicitation, driverName: this.driversList.find(driver => driver.id === solicitation.driverId)?.name };
          })
          return this.forceUpdate();
        })
      } else {
        return this.forceUpdate();
      }
    })
  }

  updateCar() {
    this.carService.getCarByDriver(this.user.uid).then((carData) => {
      this.selectedCar = carData[0];
      this.setState({ showCarModal: false, SuccessModalMessage: 'Carro atualizado com sucesso.' })
      return this.forceUpdate();
    }).catch((error) => {
      this.setState({ ErrorModalMessage: 'Não foi possivel selecionar o carro' });
      return this.forceUpdate();
    })
  }

  onModalClose() {
    this.setState({ ErrorModalMessage: '', SuccessModalMessage: '', showCarModal: false, showModal: false, showFilterModal: false });
    return this.forceUpdate();
  }

  onFilterData = (filterData) => {
    console.log(filterData);
    this.solicitationService.filterSolicitations(filterData.statusId, filterData.driverId, filterData.startDate, filterData.endDate).then((result) => {
      this.data = formatHomeData(result);
      this.forceUpdate();
      this.onModalClose();
    })
  }

  onRowClick = (uid) => {
    return this.props.navigate("/solicitation/detail/" + uid)
  }

  openSolicitationModal = () => {
    if (this.user.userInfo.voucherId) {
      this.setState({ showModal: true })
    } else {
      this.setState({ ErrorModalMessage: 'Estamos analisando sua conta, aguarde até que o administrador libere seu código de Voucher' });
    }
  }

  createNewSolicitation = (type) => {
    this.setState({ showModal: false })
    this.props.navigate("/solicitation/create/" + type);
  }

  render() {
    if (!this.user && !this.user?.userInfo) return <div></div>;
    return (
      <div className={styles.Home}>
        <div className={styles.Header}>
          <h1>Dashboard</h1>
          <div className={styles.HeaderItems}>
            <div className={styles.StatusContainer}>
            <div className={styles.StatusHeader}>
              <h2>Ultimo pedido:</h2>
              <p className={styles.StatusContent}>{this.data.length > 0 ? this.data[this.data.length - 1].voucher : ''}</p>
            </div>
            <div className={styles.StatusData}>
              <h3>{this.data.length > 0 ? this.data[this.data.length - 1].status : 'Nenhum serviço solicitado'}</h3>
            </div>
            </div>
            <div className={styles.buttonContainer}>
            {
              (this.user.userInfo && !this.user.userInfo.isDriver) &&
              <button className={styles.PrimaryButton} onClick={this.openSolicitationModal}>Solicitar Serviço</button>}
            </div>
            {this.user.userInfo && this.user.userInfo.isDriver ?
              <div className={styles.StatusContainer}>
              <div className={styles.StatusHeader}>
                <h2>Carro atual</h2>
                {this.selectedCar ?
                  <p className={styles.StatusContent}>{`${this.selectedCar.brand} ${this.selectedCar.model}, ${this.selectedCar.license}`}</p>
                  : <p className={styles.StatusContent}>Nenhum carro selecionado</p>
                }
              </div>
              <div className={styles.StatusData}>
                <button className={styles.SecondaryButton} onClick={() => this.setState({ showCarModal: true })}>Alterar carro</button>
              </div>
              </div> : <></>
            }
          </div>
        </div>
        <div className={styles.ListData}>
          <Button variant='outline-warning' className='search-button' onClick={() => this.setState({ showFilterModal: true })}><FontAwesomeIcon icon="fa-solid fa-magnifying-glass"></FontAwesomeIcon></Button>
          <FilterModal show={Boolean(this.state.showFilterModal)} drivers={this.driversList} onClose={() => this.onModalClose()} onFilter={this.onFilterData}></FilterModal>
          <div className={styles.mdHide}>
            <DataTable
              pagination
              columns={homeColumns(this.onRowClick, this.user.userInfo.isDriver)}
              data={this.data}
              sortFunction={customSort}
              noDataComponent="Não existem registros para serem exibidos."
              onColumnOrderChange={cols => console.log(cols)}
            ></DataTable>
          </div>
          <div className={styles.mdShow}>
            <DataTable
              pagination
              expandableRows
              expandableRowsComponent={TableExpanded}
              sortFunction={customSort}
              columns={homeColumns(this.onRowClick, this.user.userInfo.isDriver)}
              data={this.data}
              onColumnOrderChange={cols => console.log(cols)}
            ></DataTable>
          </div>

        </div>
        <SolicitationModal onClose={() => this.onModalClose()} show={this.state.showModal} onSelectOption={this.createNewSolicitation}></SolicitationModal>
        {
          (this.user.userInfo && this.user.userInfo.isDriver && this.state.showCarModal) &&
          <CarModal show={this.state.showCarModal} onCarUpdated={() => this.updateCar()} onClose={() => this.onModalClose()} ></CarModal>
        }
        <ErrorModal show={Boolean(this.state.ErrorModalMessage)} message={this.state.ErrorModalMessage} onClose={() => this.onModalClose()}></ErrorModal>
        <SuccessModal show={Boolean(this.state.SuccessModalMessage)} message={this.state.SuccessModalMessage} onClose={() => this.onModalClose()}></SuccessModal>
      </div>
    )
  }
};

export default Home;
