import React from 'react';
import PropTypes from 'prop-types';
import styles from './Addresses.module.scss';
import { AuthContext } from '../../../providers/AuthProvider';
import { AddressService } from '../../../services/address.service';
import { AddressColumns, customSort } from '../../../utils/dataTableColumns';
import DataTable from 'react-data-table-component';
import TableExpanded from '../../ui/tableExpanded/tableExpanded';

class Addresses extends React.Component {
  static contextType = AuthContext;
  
  data = []

  constructor(props) {
    super(props);
    this.state = {
      showModal: false
    }
  }

  async componentDidMount() {
    this.user = this.context.user
    this.addressService = new AddressService(this.user);
    this.addressService.getUserAddresses().then((result)=> {
      this.data = result;
      this.forceUpdate();
    })

  }

  onRowClick = (uid) => {
    return this.props.navigate("/address/" + uid)
  }

  render() {
    if(!this.user) return <div></div>;
    return (
      <div className={styles.Addresses}>
        <div className={styles.Header}>
          <h1>Endereços</h1>
          <div className={styles.HeaderItems}>
            <div className={styles.StatusContainer}>
            </div>
            <div className={styles.buttonContainer }>
              <button className={styles.SecondaryButton} onClick={() => this.props.navigate("/addresses/create")}>Adicionar Endereço</button>
            </div>
          </div>
        </div>
        <div className={styles.ListData}>
          <div>
            <DataTable
              pagination
              columns={AddressColumns(this.onRowClick, this.user.userInfo.isAdmin)}
              data={this.data}
              sortFunction={customSort}
              noDataComponent="Não existem registros para serem exibidos."
              ></DataTable>
          </div>
        </div>
      </div>
    )
  }
};

Addresses.propTypes = {};

Addresses.defaultProps = {};

export default Addresses;
