import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styles from './PassengersCreate.module.scss';
import { PassengerService } from '../../../services/passengers.service';
import { routeAddressModel } from '../../../models/routeAddress';
import { AddressService } from '../../../services/address.service';
import { useNavigate } from 'react-router-dom';
import { validators } from '../../../utils/validators';
import { Col, Form, Row } from 'react-bootstrap';
import SuccessModal from '../../ui/SuccessModal/SuccessModal';
import ErrorModal from '../../ui/ErrorModal/ErrorModal';
import { AuthContext } from '../../../providers/AuthProvider';
import InputMask from "react-input-mask";
import { UserService } from '../../../services/user.service';
import { passengerModel } from '../../../models/passenger';

const PassengersCreate = () => {
  const [data, setData] = useState(passengerModel);
  const [usersList, setUsersList] = useState([]);
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();
  const { user } = useContext(AuthContext)
  const passengerService = new PassengerService(user);
  const formValidators = new validators(user);
  const [validated, setValidated] = useState(false);
  const [ErrorModalMessage, setErrorModalMessage] = useState('');
  const [SuccessModalMessage, setSuccessModalMessage] = useState('');
  const [passengerUid, setPassengerUid] = useState('');

  useEffect(() => {
    if(user.userInfo.isAdmin) {
      const userService = new UserService(user);
      userService.getAllUsers().then((result) => {
        const users = result.filter((clientUser) => {
          return clientUser.userType === 'Cliente';
        })
        setUsersList(users);
      })
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if((!errors || Object.keys(errors).length === 0) && validated){
      sendToFirestore();
    };
  }, [errors]) // eslint-disable-line react-hooks/exhaustive-deps

  const handleChange = (e) => {
    const newData = { ...data, [e.target.name]: e.target.value }
    setData(newData);
  }

  const handleChangeCheck = (e) => {
    setData({ ...data, [e.target.name]: e.target.checked });
  }

  const handleOwner = (e) => {
    const owner = usersList.find(selectedUser => selectedUser.uid === e.target.value);
    const newData = { ...data, owner:owner.uid, ownerName: owner.name};
    setData(newData);
  }

  const handleSubmit = (event) => {
    setErrors({});
    event.preventDefault();
    formValidators.validatePassengerForm(data).then((res) => {
      setErrors(res);
      setValidated(true);
    })
  }

  const sendToFirestore = () => {
    passengerService.savePassenger(data).then((result) => {
      setPassengerUid(result);
      setSuccessModalMessage('Passageiro criado com sucesso!');
    }).catch(error => {
      setErrorModalMessage('Não foi possível adicionar o passageiro no momento.');
    })
  }

  const onChange = (newData) => {
    setData(newData)
  }

  const onModalClose = () => {
    setSuccessModalMessage('');
    setErrorModalMessage('');
    if(passengerUid) {
      return navigate('/passenger/' + passengerUid)
    }
    return navigate('/passengers')
  }

  return (
    <Form noValidate onSubmit={handleSubmit} className={styles.PassengersCreate}>
      <div className={styles.Header}>
        <h1>Adicionar passageiro</h1>
      </div>
      {user.userInfo.isAdmin &&
        <div className={styles.sectionContainer} >
          <h6>Dados do Usuário</h6>
          <Row className="mb-3">
            <Form.Group as={Col} md="12" controlId="owner">
              <Form.Label>Dono do endereço <strong className='text-danger'>*</strong></Form.Label>
              <Form.Select
                name='owner'
                aria-label="Selecione um usuário"
                onChange={handleOwner}>
                  {usersList && usersList.map(owner => <option value={owner.uid}>{owner.name}</option>)}
              </Form.Select>
            </Form.Group>
          </Row>
        </div>
      }
      <div className={styles.sectionContainer}>
        <h6>Dados do Passageiro</h6>
        <Row className="mb-3">
        <Form.Group as={Col} md="6" controlId="nameInput">
          <Form.Label>Nome <strong className='text-danger'>*</strong></Form.Label>
          <Form.Control
            required
            isInvalid={!data.name}
            name='name'
            type="text"
            placeholder="Nome"
            value={data.name} 
            onChange={handleChange}
          />
          <Form.Control.Feedback type="invalid">
            Por favor informe o nome do passageiro
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col} md="3" controlId="birthInput">
          <Form.Label>Data de nascimento</Form.Label>
          <InputMask type='text' name='birth' mask="99/99/9999" className='form-control' placeholder='Data de nascimento' value={data.birth} onChange={handleChange}></InputMask>
        </Form.Group>
        <Form.Group as={Col} md="3" controlId="phoneInput">
          <Form.Label>Telefone/Celular</Form.Label>
          <InputMask type='text' name='phone' mask="(99) 9 9999-9999" className={`form-control ${Boolean(errors?.phone) && 'is-invalid'}` } placeholder='Telefone' value={data.phone} onChange={handleChange}></InputMask>
          {errors?.phone && <Form.Control.Feedback type='invalid'>{errors?.phone}</Form.Control.Feedback>}
        </Form.Group>
        
        </Row>
        <Row className="mb-3">
            <Form.Group as={Col} md="9">
            </Form.Group>
            <Form.Group as={Col} md="3" controlId="notificationsInput">
              <Form.Check type="switch" name='notifications' checked={data.notifications} onChange={handleChangeCheck} label="Desejo receber notificações das solicitações por Whatsapp" />
            </Form.Group>
          </Row>
      </div>
      <div className={styles.InputContainer}>
        <div className={styles.Buttons}>
          <button className={styles.PrimaryButton} type='submit'>Confirmar</button>
          <button className={styles.SecondaryButton} onClick={() => navigate('/passengers')}>Voltar</button>
        </div>
      </div>
      <ErrorModal show={Boolean(ErrorModalMessage)} message={ErrorModalMessage} onClose={onModalClose}></ErrorModal>
      <SuccessModal show={Boolean(SuccessModalMessage)} message={SuccessModalMessage} onClose={onModalClose}></SuccessModal>
    </Form>
  );
}

PassengersCreate.propTypes = {};

PassengersCreate.defaultProps = {};

export default PassengersCreate;
