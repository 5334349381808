import React from 'react';
import DataTable from 'react-data-table-component';
import TableExpanded from '../../ui/tableExpanded/tableExpanded';
import { AuthContext } from '../../../providers/AuthProvider';
import styles from './cars.module.scss';
import { CarService } from '../../../services/car.service';
import { CarsColumns, customSort } from '../../../utils/dataTableColumns';

class Cars extends React.Component{
  static contextType = AuthContext;
  
  data = []

  constructor(props) {
    super(props);
    this.state = {
      showModal: false
    }
    
  }

  async componentDidMount() {
    this.user = this.context.user
    this.carService = new CarService(this.user);
    this.carService.getAllCars().then((result)=> {
      this.data = result;
      this.forceUpdate();
    })

  }

  onRowClick = (uid) => {
    return this.props.navigate("/car/" + uid)
  }
  
  render() {
    if(!this.user) return <div></div>;
    return (
      <div className={styles.Home}>
        <div className={styles.Header}>
          <h1>Carros</h1>
          <div className={styles.HeaderItems}>
            <div className={styles.StatusContainer}>
            </div>
            <div className={styles.buttonContainer }>
              <button className={styles.SecondaryButton} onClick={() => this.props.navigate("/car/create")}>Adicionar Carro</button>
            </div>
          </div>
        </div>
        <div className={styles.ListData}>
          <div className={styles.mdHide}>
            <DataTable
              pagination
              columns={CarsColumns(this.onRowClick)}
              data={this.data}
              sortFunction={customSort}
              noDataComponent="Não existem registros para serem exibidos."
              ></DataTable>
          </div>
          <div className={styles.mdShow}>
            <DataTable
              pagination
              expandableRows 
              expandableRowsComponent={TableExpanded}
              columns={CarsColumns(this.onRowClick, true)}
              data={this.data}
              sortFunction={customSort}
              ></DataTable>
          </div>
        </div>
      </div>
    )
  }
};

export default Cars;